<template>
  <div class="Login overflow-hidden">
    <div class="MaskGroup absolute left-0 top-0">
      <img
        :src="bgDecor"
        class="hidden md:block"
        style="width: auto; height: 95vh; object-fit: cover"
      />
      <img
        :src="group239"
        class="block md:hidden"
        style="width: 844.5px; height: 703px; object-fit: cover; object-position: 0 100%"
      />
    </div>

    <div
      class="hidden md:inline-flex space-x-2 items-center justify-end w-68 h-20 absolute md:left-12 top-0 my-3 mx-auto md:top-62"
    >
      <img class="w-18 h-18" :src="icareLogo" />
      <div class="Group28">
        <div class="text-5xl font-bold text-htextlight w-48">I Care</div>
        <div class="text-xs font-medium text-htextlight">Best Way for Your Solution</div>
      </div>
    </div>

    <div
      class="icaretitle inline-flex md:hidden space-x-2 items-center absolute justify-center w-68 h-20 mt-14 mx-auto"
    >
      <img class="w-18 h-18" :src="icareLogo" />
      <div class="Group28">
        <div class="text-5xl font-bold text-htextlight w-48">I Care</div>
        <div class="text-xs font-medium text-htextlight">Best Way for Your Solution</div>
      </div>
    </div>

    <p
      class="absolute text-base hidden md:block font-medium text-htextdark md:left-12 top-8 md:top-90"
    >
      Merupakan Mobile Ticketing App untuk <br />menangani keluhan customer <br />PT Saba Indomedika
      dan <br />PT Sysmex Indonesia. I Care juga <br />tersedia dalam versi android yang dapat
      <br />diunduh pada playstore.
    </p>

    <a
      class="btn btn-ghost p-0 absolute hidden md:block hover:border-transparent"
      href="http://play.google.com/store/apps/details?id=com.sabaindomedika.stscustomer"
      style="left: 50px; top: 510px"
    >
      <img class="w-full h-full" :src="gpIcon" />
    </a>

    <div class="Ellipse27 absolute right-0 bottom-0">
      <img :src="ellipse27" />
    </div>

    <div
      v-if="barcode"
      class="Group84 absolute md:top-32 md:right-38 bg-white shadow rounded-xl min-h-105 md:mx-0 p-2 xs:-translate-x-1/2 md:transform-none transform-gpu xs:left-p50 xs:mx-auto md:left-auto xs:top-p50 -translate-y-1/2 md:w-full xs:w-84"
      style="max-width: 419px"
    >
      <button
        class="btn btn-sm btn-ghost pr-3 pl-2 capitalize text-htext hover:border-transparent"
        @click="barcode = !barcode"
      >
        <IconArrowBack class="h-4 w-4 mr-2 stroke-current text-hblue1 pt-0.5" />Back
      </button>

      <div class="flex justify-center mt-5">
        <div
          @click="tutorial = false"
          class="sm:px-8 py-2 xs:px-7 font-semibold cursor-pointer"
          :class="!tutorial ? 'bg-hblue text-white rounded-full' : 'text-black'"
        >
          <span>QR Code</span>
        </div>
        <div
          class="px-7 py-2 font-semibold cursor-pointer"
          @click="tutorial = true"
          :class="tutorial ? 'bg-hblue text-white rounded-full' : 'text-black'"
        >
          <span>How to use ?</span>
        </div>
      </div>
      <div v-if="!tutorial">
        <div :key="`qr${qrKey}`">
          <QRCanvas
            :options="options"
            class="mx-auto mt-10 mb-3"
            :class="loadQR ? 'loading' : ''"
          ></QRCanvas>
        </div>

        <div class="mb-8 mx-auto text-center font-semibold text-lg" style="max-width: 250px">
          Scan the barcode with your device
        </div>
      </div>
      <div v-else class="tutorial-login px-3 mt-5">
        <div class="flex space-x-3">
          <div>
            <div
              class="w-6 h-6 pt-01 text-center rounded-full text-white text-sm font-semibold bg-hblue1"
            >
              1
            </div>
          </div>
          <div class="text-sm font-medium">
            Fitur ini berguna ketika anda mengakses I Care versi android dan ingin login pada
            aplikasi I Care versi web tanpa harus menuliskan username dan password kembali.
          </div>
        </div>
        <div class="flex space-x-3 mt-1">
          <div>
            <div
              class="w-6 h-6 rounded-full text-white text-center pt-0.01 text-sm font-semibold bg-hblue1"
            >
              2
            </div>
          </div>
          <div class="text-sm font-medium">
            Pada halaman utama aplikasi android, pilih menu “more” dan kemudian pilih “linked
            device” seperti pada gambar dibawah.
          </div>
        </div>
        <div class="flex space-x-3 mt-2">
          <div>
            <div class="w-6 h-6 rounded-full text-white text-sm font-semibold"></div>
          </div>
          <div>
            <img :src="androidSetting" />
          </div>
        </div>
        <div class="flex space-x-3 my-3">
          <div>
            <div
              class="w-6 h-6 rounded-full text-white text-center pt-0.01 text-sm font-semibold bg-hblue1"
            >
              3
            </div>
          </div>
          <div class="text-sm font-medium">
            Kemudian arahkan kamera ke arah QR Code untuk melanjutkan proses login menggunakan fitur
            ini.
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="Group84 absolute md:top-32 md:right-38 bg-white shadow rounded-xl md:mx-0 px-8 pb-8 xs:-translate-x-1/2 md:transform-none transform-gpu xs:left-p50 xs:mx-auto md:left-auto xs:top-p50 -translate-y-1/2 md:w-full xs:w-84"
      style="max-width: 419px"
    >
      <div
        class="Ellipse38 w-32 h-32 absolute rounded-full right-9 top-14 z-0"
        style="background-color: #ffb319"
      ></div>
      <div
        class="Ellipse39 w-10 h-10 absolute rounded-full right-48 top-38 z-0"
        style="background-color: #ffb319"
      ></div>

      <div
        class="Group36 inline-flex flex-col space-y-0.5 items-center justify-end w-full h-16 mt-20"
      >
        <p class="text-4xl font-bold text-cyan-700 text-center z-10">Welcome Back,</p>
        <p class="text-3xl font-normal text-gray-800 z-10">Please login</p>
      </div>

      <div class="flex flex-col">
        <div class="relative mt-7 z-10">
          <input
            class="appearance-none border-none shadow-md pl-12 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-xl w-full py-2 px-3 h-11.5 text-gray-800 leading-tight focus:ring-transparent text-sm"
            type="text"
            placeholder="Email or username....."
            v-model="form.username"
          />
          <div class="absolute left-0 inset-y-0 flex items-center">
            <icon-user class="h-7 w-7 ml-3 text-gray-500 hover:text-gray-800 p-0.5" />
          </div>
        </div>

        <div class="relative mt-7 z-10">
          <input
            class="text-sm appearance-none border-none shadow-md pl-12 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-xl w-full py-2 px-3 h-11.5 text-gray-800 leading-tight focus:ring-transparent"
            type="password"
            placeholder="Password....."
            v-model="form.password"
          />
          <div class="absolute left-0 inset-y-0 flex items-center">
            <icon-password class="h-7 w-7 ml-3 text-gray-500 hover:text-gray-800 p-0.5" />
          </div>
        </div>
      </div>

      <div class="Group8 h-12 mt-12 mb-5 flex space-x-4">
        <button
          class="btn rounded-xl border-0 text-white btn-primary hover:bg-hblue flex-auto"
          @click="toLogin"
          :class="loading == true ? 'loading' : ''"
          :disabled="loading"
        >
          <span class="text-sm capitalize">Login</span>
        </button>

        <button
          class="btn rounded-xl border-0 text-white btn-primary hover:bg-hblue hidden md:block"
          @click="toBarcode(true)"
          :class="loadQR ? 'animate-pulse' : ''"
        >
          <IconBarcode class="fill-current text-white mb-0.6" />
        </button>
      </div>

      <button
        class="btn text-htext w-full btn-ghost capitalize hover:border-transparent"
        @click="toCG"
      >
        Customer Group
      </button>
      <button
        class="btn text-htext w-full btn-ghost capitalize hover:border-transparent"
        @click="toResetPassword"
      >
        forgot password ?
      </button>
    </div>
  </div>
</template>

<script>
import bgDecor from '@/assets/images/bg_decor.png'
import group239 from '@/assets/images/group239.png'
import gpIcon from '@/assets/images/google_play.png'
import icareLogo from '@/assets/images/i_care_logo_large.png'
import androidSetting from '@/assets/images/android-setting.png'
import ellipse27 from '@/assets/images/ellipse27.png'

import IconUser from '@/assets/icons/icon-userOutline.svg?inline'
import IconPassword from '@/assets/icons/icon-passwordOutline.svg?inline'
import IconBarcode from '@/assets/icons/icon-barcode.svg?inline'
import IconArrowBack from '@/assets/icons/icon-arrow.svg?inline'
import { mapActions, mapState } from 'vuex'

const { QRCanvas } = require('qrcanvas-vue')

import getDevice from '../../services/getDevice'
import tokenService from '../../services/tokenService'

let browser = getDevice.BROWSER()
let osd = getDevice.OS()

export default {
  name: 'signin-page',
  components: {
    IconUser,
    IconPassword,
    IconBarcode,
    IconArrowBack,
    QRCanvas
  },
  data() {
    return {
      bgDecor,
      group239,
      gpIcon,
      qrKey: 1,
      icareLogo,
      ellipse27,
      androidSetting,

      barcode: false,
      tutorial: false,
      options: {
        cellSize: 4,
        correctLevel: 'H',
        data: null
      },
      form: {
        username: '',
        password: ''
      },
      loading: false,
      loadQR: true
    }
  },
  computed: {
    ...mapState({
      fcm: state => state.auth.fcm
    })
  },
  watch: {
    fcm: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setQR(val)
        }
      }
    }
  },
  mounted() {
    let auth = this.$store.state.auth
    if (auth.loggedIn) {
      if (auth.user && auth.user.type == 'Client') this.$router.replace({ name: 'home' })
    }

    this.redirectCheck()
  },
  methods: {
    ...mapActions('auth', ['LOGIN', 'ME', 'STORE_FCM']),
    async toLogin() {
      this.loading = true
      if (!this.form.username || !this.form.password) this.loading = false
      else {
        let resLog = await this.LOGIN(this.form)

        if (resLog) {
          let res = await this.ME()
          this.updateFCM(this.fcm)
          if (res) {
            setTimeout(() => {
              this.$router.push({ name: 'home' })
              this.loading = false
            }, 500)
          }
        }
      }

      this.loading = false
    },
    setQR(val) {
      this.loadQR = true
      const image = new Image()
      image.src = this.icareLogo
      image.onload = () => {
        this.options.data = `icustomer;${val},${osd},${browser}`

        this.options = {
          ...this.options,
          logo: {
            image
          }
        }
      }

      this.qrKey = new Date().getTime()

      setTimeout(() => {
        this.loadQR = false
      }, 1000)
    },
    toBarcode(val) {
      if (!this.loadQR) this.barcode = val
    },
    toCG() {
      window.open('http://group.i-care.id', '_blank')
    },
    toResetPassword() {
      window.open('http://support.i-care.id/reset-password/client', '_blank')
    },
    updateFCM(token) {
      let loggedIn = this.$store.state.auth.loggedIn
      let params = {}

      if (loggedIn) {
        params.fcm_token = token
        params.name = browser
        params.osd = osd
        params.device = 1
        params.timestamp = new Date().getTime()

        this.STORE_FCM(params)
      }
    },
    async redirectCheck() {
      let route = this.$route

      if (route.query && route.query.rtoken) {
        if (route.query.rtoken != 'null') {
          tokenService.setAccessToken(route.query.rtoken)

          let res = await this.ME()
          this.updateFCM(this.fcm)
          if (res) {
            setTimeout(() => {
              this.$router.push({ name: 'home' })
              this.loading = false
            }, 500)
          }
        }
      }
    }
  }
}
</script>
